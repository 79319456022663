import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RuleDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  objectId?: Nullable<number>
  relatedRecordFieldId?: Nullable<number>
  stateMatchType?: Nullable<string>
  closeMatchType?: Nullable<string>
  targetRegistryStateMatchType?: Nullable<string>
  taskRegistryStateMatchType?: Nullable<string>
  isDividedByExecutors?: Nullable<boolean>

  constructor ({ id, guid, name, objectId, relatedRecordFieldId, stateMatchType, closeMatchType, targetRegistryStateMatchType, taskRegistryStateMatchType, isDividedByExecutors }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.objectId = objectId
    this.relatedRecordFieldId = relatedRecordFieldId
    this.stateMatchType = stateMatchType
    this.closeMatchType = closeMatchType
    this.targetRegistryStateMatchType = targetRegistryStateMatchType
    this.taskRegistryStateMatchType = taskRegistryStateMatchType
    this.isDividedByExecutors = isDividedByExecutors
  }
}

export default class Rule extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private objectId: number
  private relatedRecordFieldId: number
  private stateMatchType: string
  private closeMatchType: string
  private targetRegistryStateMatchType: string
  private taskRegistryStateMatchType: string
  private isDividedByExecutors: boolean

  constructor (
    id: Nullable<number>,
    guid: Nullable<string>,
    name: string,
    objectId: number,
    relatedRecordFieldId: number,
    stateMatchType: string,
    closeMatchType: string,
    targetRegistryStateMatchType: string,
    taskRegistryStateMatchType: string,
    isDividedByExecutors: boolean
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.objectId = objectId
    this.relatedRecordFieldId = relatedRecordFieldId
    this.stateMatchType = stateMatchType
    this.closeMatchType = closeMatchType
    this.targetRegistryStateMatchType = targetRegistryStateMatchType
    this.taskRegistryStateMatchType = taskRegistryStateMatchType
    this.isDividedByExecutors = isDividedByExecutors
  }

  static create (dto: RuleDTO): Rule {
    return new Rule(
      dto.id,
      dto.guid,
      dto.name,
      dto.objectId,
      dto.relatedRecordFieldId,
      dto.stateMatchType,
      dto.closeMatchType,
      dto.targetRegistryStateMatchType,
      dto.taskRegistryStateMatchType,
      dto.isDividedByExecutors
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setObjectId (objectId: number): void {
    this.objectId = objectId
  }

  getObjectId (): number {
    return this.objectId
  }

  setRelatedRecordFieldId (relatedRecordFieldId: number): void {
    this.relatedRecordFieldId = relatedRecordFieldId
  }

  getRelatedRecordFieldId (): number {
    return this.relatedRecordFieldId
  }

  setStateMatchType (stateMatchType: string): void {
    this.stateMatchType = stateMatchType
  }

  getStateMatchType (): string {
    return this.stateMatchType
  }

  setCloseMatchType (closeMatchType: string): void {
    this.closeMatchType = closeMatchType
  }

  getCloseMatchType (): string {
    return this.closeMatchType
  }

  setTargetRegistryStateMatchType (targetRegistryStateMatchType: string): void {
    this.targetRegistryStateMatchType = targetRegistryStateMatchType
  }

  getTargetRegistryStateMatchType (): string {
    return this.targetRegistryStateMatchType
  }

  setTaskRegistryStateMatchType (taskRegistryStateMatchType: string): void {
    this.taskRegistryStateMatchType = taskRegistryStateMatchType
  }

  getTaskRegistryStateMatchType (): string {
    return this.taskRegistryStateMatchType
  }

  setIsDividedByExecutors (isDividedByExecutors: boolean): void {
    this.isDividedByExecutors = isDividedByExecutors
  }

  getIsDividedByExecutors (): boolean {
    return this.isDividedByExecutors
  }
}
